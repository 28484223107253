.prova {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-color: blue;
    color: white;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.test {
    width: 100%;
    height: 100%;
    background-color: red;
}

.test-form {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
    column-gap: 15px;
    row-gap: 15px;
    padding-top: 25px;
    padding-bottom: 15px;
}
.test-form-no-padding {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
    column-gap: 15px;
    row-gap: 15px;
}

.contenitore-bottoni-destra {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    column-gap: 10px;
    row-gap: 10px;
    justify-content: end;
    margin-top: 10px;
    box-sizing: border-box;
    margin-top: auto;
    padding-top: 20px;
}
.contenitore-bottoni-destra-verticale {
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    row-gap: 10px;
    justify-content: end;
    margin-top: 10px;
    box-sizing: border-box;
    margin-top: auto;
    padding-top: 20px;
}

.box-dashboard {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content 1fr max-content;
}

.alert {
    height: max-content;
    position: fixed;
    bottom: 80px;
    right: 30px;
}

.contenitore-field-output {
    width: 100%;
}

.flex-a-capo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.contenitore-form-bottoni {
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    flex-direction: column;
}

.centrato {
    display: flex;
    justify-content: center;
    align-items: center;
}

.manina {
    cursor: pointer;
}

.fletti-orizzontalmente {
    display: flex;
    flex-direction: row;
}
.fletti-verticalmente {
    display: flex;
    flex-direction: column;
}

/* inizio bandire */
.contenitore-bandiera {
    box-sizing: border-box;
    width: 77px;
    height: 77px;
    margin: 10px;
    display: grid;
    grid-template-rows: 1fr min-content;
    flex-direction: column;
}
.contenitore-bandiera .bandiera {
    max-height: 50px;
}
.bandiera {
    cursor: pointer;

    box-sizing: border-box;

    transition: transform 150ms ease;
}
.selezionato {
    transform: scale(1.5);
    filter: drop-shadow(4px 5px 3px rgba(0, 0, 0, 0.2));
}

.non-selezionato {
    transform: scale(0.5);
}
.info-bandiera {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
}
.red > svg {
    fill: red;
}

.orange > svg {
    fill: #ff5722;
}

.yellow > svg {
    fill: #ffeb3b;
}
.blue > svg {
    fill: #2196f3;
}
.black > svg {
    fill: #212121;
}
/* fine bandire */

/* inizio test-articolare */
.container-test-articolare {
    margin-top: 50px;
    position: relative;
    max-width: 500px;
    width: 100%;
    margin: 0px;
    border-radius: 12px;
    display: flex;
    box-sizing: border-box;
}
.canvas-test-articolare {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}
#immagine-test-articolare {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
}

.test-articolare {
    box-sizing: border-box;
    display: flex;
}
/* fine test-articolare */

/* inizio body-chart */
.container-body-chart-output {
    width: 201.75px;
    height: 201.75px;
    display: flex;
    box-sizing: border-box;
    position: relative;
    margin: 0 auto;
}


#parte-sopra-body-chart{
    border-bottom: 1px solid #0b49b3;
}
#parte-sotto-body-chart{
    border-top: 1px solid #0b49b3;
}

.img-body-chart-output {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0px;
    box-sizing: border-box;
}

#contenitore-body-chart {
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 50px max-content 50px;
    border-radius: 12px;
    border: 1px solid #0b49b3;
}

#parte-centrale-body-chart {
    width: 100%;
    box-sizing: border-box;
    
    position: relative;
}
.comandi-body-chart {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 5px;
}

#lista-colori-body-chart {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}

.colore-body-chart {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 5px;
    box-sizing: border-box;
    cursor: pointer;
    transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.colore-selezionato-body-chart {
    transform: scale(1.5);
    z-index: 2;
}

/* fine body-chart */
.griglia-sopra-sotto {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr max-content;
    row-gap: 10px;
}
.scroll-verticale {
    box-sizing: border-box;
    overflow-y: auto;
}

/* inizio chart dolori */

.container-chart-dolori {
    display: flex;
    position: relative;
    max-width: 600px;
    width: 100%;
    height: 100%;
    margin: 0px;
    box-sizing: border-box;
}

#lavagna {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0;
    right: 0;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 12px;
}
.pointer {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background-color: red;
    transform: translate(-50%, -50%);
    z-index: 1;
}
.vecchio {
    background-color: var(--blu-phisio);
    box-sizing: border-box;
    padding: 5px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0b49b3 !important;
}
/* fine chart dolori */
::-webkit-scrollbar {
    width: 0px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

.icona-menu > svg {
    fill: #ffffff;
}
.icona-menu-selezionata > svg {
    fill: #0b49b3;
}
.canvas-cont {
    box-sizing: border-box;
    height: 170px;
    width: 300px;
    background-color: #c4c4c4;
}
.prova-input {
    color: white !important;
}

.wrapper-field-dolori {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 992px) {
    .wrapper-field-dolori {
        flex-direction: row;
    }
}

.contenitore-seduta {
    box-sizing: border-box;
    list-style-type: none;
    column-count: 2;
}
.elemento-seduta {
    height: 100% !important;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    box-sizing: border-box;
    break-inside: avoid-column;
}

@media screen and (max-width: 768px) {
    .contenitore-seduta {
        column-count: 1;
    }
}

.immagine-landing {
    object-fit: scale-down;
}

/* TODO per albi*/
.pop-up-pdf {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    background-color: white !important;
    transition: left 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    z-index: 9000;
    min-height: 100vh;
    background-color: white;
    /* opacity: 0; */
}
.pop-up-pdf-aperto {
    left: 0 !important;
}

/* .elemento-seduta-pdf{
    height: 100px;
    width : 100%;
    margin-bottom: 20px;
    background: green;
} */

.chiudi-pop-up {
    position: fixed;
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: 20px;
    right: 50px;
    bottom: 50px;
}

.flex-responsivo {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 576px) {
    .flex-responsivo {
        flex-direction: column;
    }
}

@media print and (max-width: 122222px) {
    .dolori {
        /* transform: translateY(130px) !important; */
        page-break-inside: avoid !important;
        break-inside: avoid !important;
        position: relative;
        width: 100%;
    }
    .elemento-seduta-pdf {
        page-break-inside: avoid !important;
        break-inside: avoid !important;
        /*   break-after: always;
        break-before: avoid;
        page-break-after: always;
        page-break-before: avoid; */
        position: relative;
        width: 100%;
    }
    .chiudi-pop-up {
        display: none !important;
    }
    .MuiDrawer-paper {
        display: none !important;
    }
}

@page {
    size: A4;
    margin: 0;
    display: block;
}
@page {
    size: auto; /* auto is the initial value */
    margin: 10px; /* this affects the margin in the printer settings */
}
/* html,body{
  width:100%;
  overflow-x: hidden;
}
#root{
  width: 100%;
  overflow-x: hidden;
} */

.contenitore-immagine-nome{
    width: max-content;
    
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows:  max-content 1fr;
    row-gap: 10px;
}

.contenitore-bottoni-esercizio {
    width : 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    row-gap: 5px;
}

.video-landing{
    min-height: 450px !important;
}

@media screen and (max-width: 576px) {
    .contenitore-bottoni-esercizio {
        width : 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        row-gap: 0px;
        column-gap: 5px;
    }
    .video-landing{
        min-height: 300px !important;
    }
}