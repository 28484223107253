.rbc-btn {
    color: inherit;
    font: inherit;
    margin: 0; 
}
  
  button.rbc-btn {
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer; }
  
  button[disabled].rbc-btn {
    cursor: not-allowed; }
  
  button.rbc-input::-moz-focus-inner {
    border: 0;
    padding: 0; }
  
  .rbc-calendar {
    box-sizing: border-box;
    height: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch; }
  
  .rbc-calendar *,
  .rbc-calendar *:before,
  .rbc-calendar *:after {
    box-sizing: inherit; }
  
  .rbc-abs-full,
  .rbc-row-bg {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

    .rbc-event-label{
      display: none !important;
    }
  
  .rbc-ellipsis,
  .rbc-event-label,
  .rbc-row-segment .rbc-event-content,
  .rbc-show-more {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  
  .rbc-rtl {
    direction: rtl; }
  
  .rbc-off-range {
    color: #333333;
    opacity: 0.3; }
  
  .rbc-header {
    overflow: hidden;
    -webkit-flex: 1 0 0%;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 3px;
    text-align: right;
    vertical-align: middle;
    font-weight: bold;
    font-size: 1em;
    min-height: 0;
    border-bottom: 1px solid #ddd;
    color: #999999; }
    .rbc-header:last-child span {
      padding-right: 15px; }
  
  .rbc-header > a,
  .rbc-header > a:active,
  .rbc-header > a:visited {
    color: inherit;
    text-decoration: none; }
  
  .rbc-row-content {
    position: relative;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    z-index: 4;
    min-height: 90px; 
    cursor: pointer;
}
  
  .rbc-toolbar {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px; 
    flex-direction: row;
}
  
  .rbc-toolbar .rbc-toolbar-label {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 0 10px;
    text-align: center;
    font-family: inherit;
    line-height: 1.5em;
    color: inherit;
    font-weight: 300;
    margin: 0;
    font-size: 1.8em; 
}
  
  .rbc-toolbar button {
    width : 100%;
    box-shadow: 0 2px 2px 0 #0b49b3, 0 3px 1px -2px #0b49b3, 0 1px 5px 0 #0b49b3;
    border-width: 2px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 5px 1px;
    border: none;
    margin: 10px 1px;
    border-radius: 30px;
    padding: 6px 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    outline: none; }
    .rbc-toolbar button, .rbc-toolbar button:hover, .rbc-toolbar button:focus, .rbc-toolbar button:active, .rbc-toolbar button.active, .rbc-toolbar button:active:focus, .rbc-toolbar button:active:hover, .rbc-toolbar button.active:focus, .rbc-toolbar button.active:hover,
    .open > .rbc-toolbar button.dropdown-toggle,
    .open > .rbc-toolbar button.dropdown-toggle:focus,
    .open > .rbc-toolbar button.dropdown-toggle:hover {
      background-color: #0b49b3;
      color: #ffffff; }
    .rbc-toolbar button:focus, .rbc-toolbar button:active, .rbc-toolbar button:hover {
        box-shadow: 0 2px 2px 0 #0b49b3, 0 3px 1px -2px #0b49b3, 0 1px 5px 0 #0b49b3; }
    .rbc-toolbar button.disabled, .rbc-toolbar button.disabled:hover, .rbc-toolbar button.disabled:focus, .rbc-toolbar button.disabled.focus, .rbc-toolbar button.disabled:active, .rbc-toolbar button.disabled.active, .rbc-toolbar button:disabled, .rbc-toolbar button:disabled:hover, .rbc-toolbar button:disabled:focus, .rbc-toolbar button:disabled.focus, .rbc-toolbar button:disabled:active, .rbc-toolbar button:disabled.active, .rbc-toolbar button[disabled], .rbc-toolbar button[disabled]:hover, .rbc-toolbar button[disabled]:focus, .rbc-toolbar button[disabled].focus, .rbc-toolbar button[disabled]:active, .rbc-toolbar button[disabled].active,
    fieldset[disabled] .rbc-toolbar button,
    fieldset[disabled] .rbc-toolbar button:hover,
    fieldset[disabled] .rbc-toolbar button:focus,
    fieldset[disabled] .rbc-toolbar button.focus,
    fieldset[disabled] .rbc-toolbar button:active,
    fieldset[disabled] .rbc-toolbar button.active {
      box-shadow: none; }
    .rbc-toolbar button.btn-simple {
      background-color: transparent;
      color: #9c27b0;
      box-shadow: none; }
      .rbc-toolbar button.btn-simple:hover, .rbc-toolbar button.btn-simple:focus, .rbc-toolbar button.btn-simple:active {
        background-color: transparent;
        color: #9c27b0; }
  
  .rbc-toolbar button:focus {
    outline: none; }
  
  .rbc-btn-group {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    column-gap: 5px;
}
  
  .rbc-btn-group button {
    margin: 0 0 0 2px;
    border-radius: 30px; }

    @media screen and (max-width: 768px) {
      .rbc-event-label{
        display: none !important;
      }
      .rbc-event{
        border-radius: 4px !important;
      }
    }
  
  @media screen and (max-width: 992px) {
    .rbc-toolbar button:focus, .rbc-toolbar button:active, .rbc-toolbar button:hover {
      box-shadow: unsetò }
    .open > .rbc-toolbar button.dropdown-toggle:hover {
      background-color: transparent;
      color: black; }

    .open > .rbc-toolbar button.dropdown-toggle:hover,.rbc-toolbar button:hover {
      background-color:transparent;
      color: black; }
    .rbc-toolbar button:focus, .rbc-toolbar button:active, .rbc-toolbar button:hover {
        box-shadow: unset; }

       

    .rbc-toolbar button{
        background-color: white !important;
        color: black;
        border-radius: 0px;
        border: none;
        box-shadow: unset;
    }

    .rbc-toolbar {
        flex-direction: column !important;
    }
    .rbc-btn-group{
        border-bottom: solid;
        border-top: solid;
        margin: 2px !important; 
    } 
  }
  
  .rbc-btn-group button + button {
    margin: 0 0 0 2px;
    border-radius: 30px; }
  
  .rbc-btn-group + .rbc-btn-group,
  .rbc-btn-group + button {
    margin-left: 10px; }
  
  .rbc-slot-selecting .rbc-event {
    cursor: inherit;
    pointer-events: none; }
  
  .rbc-event.rbc-selected {
    background-color: #265985; }
  
  .rbc-event-label {
    font-size: 80%; }
  
  .rbc-event-overlaps {
    box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5); }
  
  .rbc-event-continues-prior {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  
  .rbc-event-continues-after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  
  .rbc-event-continues-earlier {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  
  .rbc-event-continues-later {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  
  .rbc-event-continues-day-after {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  
  .rbc-event-continues-day-prior {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  
  .rbc-row {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; 
}
  
  .rbc-row-segment {
    padding: 2px 6px 1px; }
  
  .rbc-selected-cell {
    background-color: rgba(0, 0, 0, 0.1); }
  
  .rbc-show-more {
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 4;
    font-weight: bold;
    font-size: 85%;
    height: auto;
    line-height: normal;
    white-space: nowrap; }
  
  .rbc-month-view {
    position: relative;
    border: 1px solid #ddd;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    width: 100%;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    height: 100%;
    border-left: none;
    border-right: none; }
  
  .rbc-month-view {
    border-top: none; }
  
  .rbc-month-header {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
  
  .rbc-month-row {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    -ms-flex-preferred-size: 0px;
    overflow: hidden;
    height: 100%; }
  
  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid #ddd; }
  
  .rbc-date-cell {
    -webkit-flex: 1 1 0;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    min-width: 0;
    padding-right: 5px;
    text-align: right; 
}
  
  .rbc-date-cell.rbc-now {
    font-weight: bold; }
  
  .rbc-date-cell > a,
  .rbc-date-cell > a:active,
  .rbc-date-cell > a:visited {
    color: inherit;
    text-decoration: none; }
  
  .rbc-row-bg {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    overflow: hidden; 
}
  
  .rbc-day-bg {
    -webkit-flex: 1 0 0%;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%; }
  
  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #ddd; }
  
  .rbc-rtl .rbc-day-bg + .rbc-day-bg {
    border-left-width: 0;
    border-right: 1px solid #ddd; }
  
  .rbc-overlay {
    position: absolute;
    z-index: 5;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    padding: 10px; }
  
  .rbc-overlay > * + * {
    margin-top: 1px; }
  
  .rbc-overlay-header {
    border-bottom: 1px solid #e5e5e5;
    margin: -10px -10px 5px -10px;
    padding: 2px 10px; }
  
  .rbc-agenda-view {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    overflow: auto; }
  
  .rbc-agenda-view table.rbc-agenda-table {
    width: 100%;
    border: 1px solid #ddd;
    border-spacing: 0;
    border-collapse: collapse; }
  
  .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
    padding: 5px 10px;
    vertical-align: top; }
  
  .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
    padding-left: 15px;
    padding-right: 15px;
    text-transform: lowercase; }
  
  .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left: 1px solid #ddd; }
  
  .rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left-width: 0;
    border-right: 1px solid #ddd; }
  
  .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
    border-top: 1px solid #ddd; }
  
  .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    padding: 3px 5px;
    text-align: left;
    border-bottom: 1px solid #ddd; }
  
  .rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    text-align: right; }
  
  .rbc-agenda-time-cell {
    text-transform: lowercase; }
  
  .rbc-agenda-time-cell .rbc-continues-after:after {
    content: " »"; }
  
  .rbc-agenda-time-cell .rbc-continues-prior:before {
    content: "« "; }
  
  .rbc-agenda-date-cell,
  .rbc-agenda-time-cell {
    white-space: nowrap; }
  
  .rbc-agenda-event-cell {
    width: 100%; }
  
  .rbc-time-column {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100%; }
  
  .rbc-time-column .rbc-timeslot-group {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1; }
  
  .rbc-timeslot-group {
    border-bottom: 1px solid #ddd;
    min-height: 40px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap; 

  }
    
  
  .rbc-time-gutter,
  .rbc-header-gutter {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none; }
  
  .rbc-label {
    padding: 0 5px; }
  
  .rbc-day-slot {
    position: relative; }
  
  .rbc-day-slot .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 10px;
    top: 0; 
    cursor: pointer;
  }
  
  .rbc-day-slot .rbc-events-container.rbc-is-rtl {
    left: 10px;
    right: 0; }
  
  .rbc-day-slot .rbc-event {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-height: 100%;
    min-height: 20px;
    -webkit-flex-flow: column wrap;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    overflow: hidden;
    position: absolute; }
  
  .rbc-day-slot .rbc-event-label {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    padding-right: 5px;
    width: auto;
    font-weight: bold;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    color: #fff;
    white-space: nowrap; }
  
  .rbc-day-slot .rbc-event-content {
    width: 100%;
    -webkit-flex: 1 1 0;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    word-wrap: break-word;
    line-height: 1;
    height: 100%;
    min-height: 1em; }
  
  .rbc-day-slot .rbc-time-slot + .rbc-time-slot {
    border-top: 1px dotted #ddd; }
  
  .rbc-time-slot {
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0px;
    flex: 1 0 0; }
  
  .rbc-time-slot.rbc-now {
    font-weight: bold; }
  
  .rbc-day-header {
    text-align: center; }
  
  .rbc-slot-selection {
    z-index: 10;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 75%;
    width: 100%;
    padding: 3px; }
  
  .rbc-slot-selecting {
    cursor: move; }
  
  .rbc-time-view {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    border: 1px solid #ddd;
    min-height: 0;
    border-left: none;
    border-right: none;
    border-top: none; }
  
  .rbc-time-view .rbc-time-gutter {
    white-space: nowrap; }
  
  .rbc-time-view .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    position: relative; }
  
  .rbc-time-view .rbc-allday-events {
    position: relative;
    z-index: 4; }
  
  .rbc-time-view .rbc-row {
    box-sizing: border-box;
    min-height: 20px; }
  
  .rbc-time-header {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
  
  .rbc-time-header.rbc-overflowing {
    border-right: 1px solid #ddd; }
  
  .rbc-rtl .rbc-time-header.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid #ddd; }
  
  .rbc-time-header > .rbc-row:first-child {
    border-bottom: 1px solid #ddd; }
  
  .rbc-time-header > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #ddd; }
  
  .rbc-time-header-content {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-width: 0;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-left: 1px solid #ddd; }
  
  .rbc-rtl .rbc-time-header-content {
    border-left-width: 0;
    border-right: 1px solid #ddd; }
  
  .rbc-time-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1 0 0%;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%;
    border-top: 2px solid #ddd;
    overflow-y: auto;
    position: relative; }
  
  .rbc-time-content > .rbc-time-gutter {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none; }
  
  .rbc-time-content > * + * > * {
    border-left: 1px solid #ddd; }
  
  .rbc-rtl .rbc-time-content > * + * > * {
    border-left-width: 0;
    border-right: 1px solid #ddd; }
  
  .rbc-time-content > .rbc-day-slot {
    width: 100%;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none; }
  
  .rbc-current-time-indicator {
    position: absolute;
    z-index: 3;
    height: 1px;
    background-color: #74ad31;
    pointer-events: none; }
  
  .rbc-event {
    width: 100%;
    position: relative;
    /* for resize handle and other inner positioning */
    display: block;
    /* make the <a> tag block */
    font-size: 0.85em;
    border-radius: 12px;
    padding: 3px;
    background-color: #1A73E8;
    /* default BACKGROUND color */
    font-weight: normal;
    /* undo jqui's ui-widget-header bold */
    text-align: right;
    border: none;
    padding-bottom: 1px;
    line-height: 1.2; }
    .rbc-event.event-azure {
      background-color: #00bcd4;
      box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4); }
    .rbc-event.event-green {
      background-color: #4caf50;
      box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4); }
    .rbc-event.event-orange {
      background-color: #ff9800;
      box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4); }
    .rbc-event.event-red {
      background-color: #f44336;
      box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4); }
    .rbc-event.event-rose {
      background-color: #e91e63;
      box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4); }
    .rbc-event.event-default {
      background-color: #999999;
      box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(153, 153, 153, 0.4); }
    .rbc-event .rbc-event-content {
      position: relative;
      z-index: 2;
      white-space: nowrap;
      overflow: hidden;
      color: #fff;
      padding: 0 5px; }
  
  .rbc-day-slot .rbc-event {
    display: block !important;
    max-height: unset !important; }

    @media (max-width: 526px) {
      .rbc-header{
        white-space: unset !important;
        text-align: left !important;
        font-size: 0.6em;
      }
    }

    